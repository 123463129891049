<template>
  <section class="section-4">
    <div class="content">
      <div class="title">小程序</div>
      <div class="line">
        <div class="line-color" />
      </div>
      <div class="main">
        <div class="mini-img">
          <img src="../../../assets/images/home/iphone.png" alt="" />
        </div>
        <div class="text">
          <div class="eyebrow">
            <p>支付宝/微信小程序和为终端用户操作平台，</p>
            <p>小程序为主要入口，具有卫生间导航/卫生间</p>
            <p>信息、扫码出纸、卫生间评分等功能。</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slide1",
  components: {},
};
</script>

<style scoped lang="less">
.section-4 {
  position: relative;
  width: 100vw;
  height: 100%;
  background: linear-gradient(180deg, #fefefe 0%, #e9ecf1 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .content {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 665px;
    display: flex;
    flex-direction: column;

    > .title {
      margin-left: 611px;
      width: 284px;
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      display: flex;
      justify-content: center;
    }

    > .line {
      margin-top: 40px;
      width: 1200px;
      height: 4px;
      background: #ffffff;
      position: relative;

      .line-color {
        margin-left: 611px;
        width: 284px;
        height: 4px;
        background: #fe690c;
      }
    }
    .main {
      margin: 40px auto 0;
      width: 720px;
      height: 552px;
      display: flex;
      justify-content: space-between;
      .mini-img {
        width: 275px;
        height: 552px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        margin-top: 232px;
        .eyebrow {
          height: 78px;
          font-size: 14px;
          font-weight: 400;
          color: #555555;
          line-height: 26px;
        }
      }
    }
    .bg-word {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
